<template>
  <div v-if="shipment">
    <section class="section is-main-section">
      <div class="columns">
        <div class="column">
          <h2 class="title">ເອົາເຄື່ອງໃຫ້ລູກຄ້າ - {{ code }}</h2>
          <h1 class="title is-4">{{ shipment.receivername }} - {{ shipment.receivertel }}</h1>
        </div>
      </div>

      <div class="columns">
        <div class="column" >
          <div class="panel is-primary">
            <div class="panel-heading">
              ຄ່າຂົນສົ່ງ
            </div>
            <div>
              <table class="table is-bordered is-fullwidth" >
                <thead>
                <tr>
                  <th colspan="2" >ຄ່າສົ່ງ</th>
                  <th><h2 class="title is-4">{{ shipment.amount| formatnumber }} ກີບ</h2></th>
                  <th ><h2 class="title is-4">{{ shipment.amount_thb | formatnumber }} ບາດ</h2></th>
                </tr>
                <tr v-if="!shipment.is_receiver_pay">
                  <th colspan="4" >ຊຳລະແລ້ວ</th>
                </tr>
                <tr v-if="shipment.is_receiver_pay">
                  <th class="has-text-centered" style="width: 10%">#</th>
                  <th class="has-text-centered" style="width: 20%">
                    ວິທີ
                  </th>
                  <th class="has-text-centered">
                    <b-field label="" horizontal class="has-text-centered">
                      <b-radio
                          v-if="shipment.amount > 0"
                          v-model="fee_ccy"
                          :native-value="'LAK'"
                      >
                        ກີບ (LAK)
                      </b-radio>
                      <b-radio
                          v-if="shipment.amount_thb > 0"
                          v-model="fee_ccy"
                          :native-value="'THB'"
                      >
                        ບາດ (THB)
                      </b-radio>
                    </b-field>
                  </th>
                  <th>ໝາຍເຫດ</th>
                </tr>
                </thead>
                <tbody v-if="shipment.is_receiver_pay">
                <tr v-for="(item, index) in fee_payments" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="has-text-centered">
                    <button class="button is-text"  @click="fillFee(index)">
                      {{ item.name }}
                    </button>
                  </td>
                  <td>
                    <b-input
                        v-model="fee_payments[index]['amount']"
                        :placeholder="fee_ccy"
                        class="has-text-right"
                        type="number"
                        :min="0"
                    />
                  </td>
                  <td>
                    <b-input
                        v-model="fee_payments[index]['remark']"
                        placeholder="ໝາຍເຫດ"
                    />
                  </td>
                </tr>
                </tbody>
                <tfoot v-if="shipment.is_receiver_pay">
                <tr>
                  <th
                      class="has-text-centered"
                      colspan="2"
                      style="font-size: 1.5em"
                  >
                    ລວມຄ່າສົ່ງ
                  </th>
                  <th style="font-size: 1.5em" class="has-text-right">
                    {{ totalFeePayment | formatnumber }}
                  </th>
                  <th>

                    <span class="tag is-warning" v-if="totalFeePayment < (fee_ccy === 'LAK' ? shipment.amount: shipment.amount_thb)">ຈຳນວນຈ່າຍ ໜ້ອຍກວ່າ ຄ່າສົ່ງ</span>
                    <span class="tag is-danger" v-if="totalFeePayment > (fee_ccy === 'LAK' ? shipment.amount: shipment.amount_thb)">ຈຳນວນຈ່າຍ ຫຼາຍກວ່າ ຄ່າສົ່ງ</span>

                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div class="panel is-info" v-if="shipment.is_cod">
            <div class="panel-heading">
              ຄ່າ COD
            </div>
            <div>
              <table class="table is-fullwidth is-bordered">
                <thead >
                <tr>
                  <th colspan="2" >ຄ່າເຄື່ອງ COD</th>
                  <th><h2 class="title is-4">{{ totalCodLak| formatnumber }} ກີບ</h2></th>
                  <th><h2 class="title is-4">{{ totalCodThb | formatnumber }} ບາດ</h2></th>
                </tr>
                <tr>
                  <th class="has-text-centered" style="width: 10%">#</th>
                  <th class="has-text-centered" style="width: 20%">
                    ວິທີຊຳລະ
                  </th>
                  <th class="has-text-centered">
                    <b-field label="" horizontal class="has-text-centered">
                      <b-radio
                          v-if="totalCodLak > 0"
                          v-model="ccy"
                          :native-value="'LAK'"
                      >
                        ກີບ (LAK)
                      </b-radio>
                      <b-radio
                          v-if="totalCodThb > 0"
                          v-model="ccy"
                          :native-value="'THB'"
                      >
                        ບາດ (THB)
                      </b-radio>
                    </b-field>
                  </th>
                  <th>ໝາຍເຫດ</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in payments" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="has-text-centered">
                    <button class="button is-text" @click="fillCod(index)">
                      {{ item.name }}
                    </button>
                  </td>
                  <td>
                    <b-input
                        v-model="payments[index]['amount']"
                        :placeholder="ccy"
                        class="has-text-right"
                        type="number"
                        :min="0"
                    />
                  </td>
                  <td>
                    <b-input
                        v-model="payments[index]['remark']"
                        placeholder="ໝາຍເຫດ"
                    />
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th
                      class="has-text-centered"
                      colspan="2"
                      style="font-size: 1.5em"
                  >
                    ລວມ
                  </th>
                  <th style="font-size: 1.5em" class="has-text-right">
                    {{ totalPayment | formatnumber }}
                  </th>
                  <th>

                    <span class="tag is-warning" v-if="totalPayment < (ccy === 'LAK' ? totalCodLak: totalCodThb)">ຈຳນວນຈ່າຍ ໜ້ອຍກວ່າ ຄ່າເຄື່ອງ</span>
                    <span class="tag is-danger" v-if="totalPayment > (ccy === 'LAK' ? totalCodLak: totalCodThb)">ຈຳນວນຈ່າຍ ຫຼາຍກວ່າ ຄ່າເຄື່ອງ</span>

                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div class="column ">
          <h1 class="title has-text-centered">ລວມເງິນທີ່ຕ້ອງຮັບ</h1>
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">ກີບ</p>
                <p class="title">{{
                    (shipment.is_receiver_pay  && fee_ccy === 'LAK' ? shipment.amount : 0) + (ccy === 'LAK' ?totalCodLak:0) | formatnumber
                  }}</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">ບາດ</p>
                <p class="title">
                <p class="title">{{
                    (shipment.is_receiver_pay && fee_ccy === 'THB' ? shipment.amount_thb : 0) + (ccy === 'THB' ?totalCodThb:0) | formatnumber
                  }}
                </p>
              </div>
            </div>

          </nav>
        <div class="tags is-centered">
          <span class="tag is-warning" v-if="totalPayment < (ccy === 'LAK' ? totalCodLak: totalCodThb)">ຈຳນວນຈ່າຍ COD ໜ້ອຍກວ່າ ຄ່າເຄື່ອງ</span>
          <span class="tag is-danger" v-if="totalPayment > (ccy === 'LAK' ? totalCodLak: totalCodThb)">ຈຳນວນຈ່າຍ COD ຫຼາຍກວ່າ ຄ່າເຄື່ອງ</span>

          <span class="tag is-warning" v-if="totalFeePayment < (fee_ccy === 'LAK' ? shipment.amount: shipment.amount_thb)">ຈຳນວນຈ່າຍຄ່າສົ່ງ ໜ້ອຍກວ່າ ຄ່າສົ່ງ</span>
          <span class="tag is-danger" v-if="totalFeePayment > (fee_ccy === 'LAK' ? shipment.amount: shipment.amount_thb)">ຈຳນວນຈ່າຍຄ່າສົ່ງ ຫຼາຍກວ່າ ຄ່າສົ່ງ</span>

        </div>
          <div class="has-text-centered">
            <b-button
                class="is-success is-large"
                :disabled="(totalPayment !== (ccy === 'LAK' ? totalCodLak: totalCodThb)) || (totalFeePayment !== (shipment.is_receiver_pay ? (fee_ccy === 'LAK' ? shipment.amount: shipment.amount_thb):0))"
                @click="save()"
                icon-left="check"
            >
              ບັນທຶກ
            </b-button>
          </div>
        </div>
      </div>



    </section>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { get } from "vuex-pathify";
export default {
  name: "ToDeliverParcel",
  computed: {
    user: get("user"),
    shipmentStatuses: get("shipmentStatuses"),
    paymentmethods: get("paymentmethods"),
    exchangerate: get("exchangerate"),
    totalCodThb(){
      if(this.shipment == null ) return 0;
      if(!this.shipment.is_cod) return 0;
      return this._.sumBy(this.shipment.cod, itm => itm.ccy === 'THB' ? itm.amount : 0)
    },
    totalCodLak(){
      if(this.shipment == null) return 0
      if(!this.shipment.is_cod) return 0;
      return this._.sumBy(this.shipment.cod, itm => itm.ccy === 'LAK' ? itm.amount : 0)
    },
    totalFeePayment() {
      let sum = 0;
      if (this.fee_payments) {
        this.fee_payments.forEach((p) => {
          sum += p.amount * (this.fee_ccy === "LAK" ? 1000 : 1);
        });
      }
      return sum;
    },
    totalPayment() {
      let sum = 0;
      if (this.payments) {
        this.payments.forEach((p) => {
          sum += p.amount * (this.ccy === "LAK" ? 1000 : 1);
        });
      }
      return sum;
    },

    availablePaymentMethod() {
      if(!this.paymentmethods) return [];
      return this._.filter(this.paymentmethods, m => m.id !== 'debt');
    },

    feeAvailablePaymentMethod() {
      if(!this.paymentmethods) return [];
      return this._.filter(this.paymentmethods, m => m.id !== 'debt' && m.id !== 'selftransfer' && m.id !== 'debt');
    }
  },
  data() {
    return {
      ccy: "LAK",
      ccys: [{ code: "LAK" }, { code: "THB" }],
      code: null,
      shipment: null,
      isShowModalDelivery: false,
      amount: null,
      trackings: null,
      isModalPdfShowing: false,
      pdfDataUrl: null,
      activeTab: 0,
      isLoading: false,
      payments: [],
      fee_payments: [],
      fee_ccy:'LAK'
    };
  },
  mounted() {
    this.code = this.$route.params["code"];
    if (this.code) {
      this.get();
    }
  },
  methods: {
    get() {
      axios
        .get("shipments/get/" + this.code)
        .then((r) => {
          this.shipment = r;
          this.payments = [];
          this.fee_payments = [];

            this.availablePaymentMethod.forEach((paymentMethod) => {
              this.payments.push({
                amount: null,
                type: paymentMethod.id,
                name: paymentMethod.name,
              });


            });
            this.feeAvailablePaymentMethod.forEach((paymentMethod) => {
              this.fee_payments.push({
                amount: null,
                type: paymentMethod.id,
                name: paymentMethod.name,
              });
            });

        })
        .catch((e) => {
          console.log(e);
          Swal.fire("ບໍ່ພົບ", e, "info");
        });
    },
    save() {
      this.isLoading = true;




      const newPayments = this._.filter(this.payments, (p) => p.amount && p.amount > 0);
      const newFeePayments = this._.filter(this.fee_payments, (p) => p.amount && p.amount > 0);




      const newShipment = this.shipment;
      newShipment.exchangerate = this.exchangerate;
      axios
        .post(`parcels/delivernew/${newShipment.id}`, {
          ccy: this.ccy,
          shipment: newShipment,
          payments: newPayments,
          fee_payments: newFeePayments,
          fee_ccy: this.fee_ccy
        })
        .then((r) => {
          this.isLoading = false;
          console.log(r);
          Swal.fire("ສຳເລັດ", "", "success").then(() => {
            this.get();
            this.$router.replace(`/shipment/${this.code}`);
          });
        }).catch(()=> {
        this.isLoading = false;
      });
    },
    fillFee(index) {
      console.log('fee', index)
      for(let i =0; i < this.fee_payments.length; i++){
        if(i === index) {
          this.fee_payments[i]['amount'] = this.fee_ccy === 'LAK' ? this.shipment.amount / 1000 : this.shipment.amount_thb
        }else {
          this.fee_payments[i]['amount'] = 0
        }
      }


    },
    fillCod(index) {
      console.log('cod',index)
      for(let i =0; i < this.payments.length; i++){
        if(i === index) {
          this.payments[i]['amount'] = this.ccy === 'LAK' ? this.totalCodLak / 1000 : this.totalCodThb
        }else {
          this.payments[i]['amount'] = 0
        }
      }
    }
  },
};
</script>
<style>
.table__wrapper {
  overflow-x: auto;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
